import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const Image = ({
  data: image,
  loading = "lazy",
  placeholderClassName = "",
  title = "",
  classes = "",
}) => {
  // const sizes = "(max-width: 640px) 768px, (max-width: 960px) 1200px, (max-width: 1280px) 1600px, 1920px"
  // const sizes = "20vw"
  const sizes = "(max-width: 640px) 100vw, (max-width: 1366px) 50vw, 1920w"
  // const srcSet = image.node.srcSet

  const overriddenHeroImageSizes = {
    ...image?.node?.localFile?.childImageSharp?.fluid,
    sizes,
    // srcSet,
  }

  // console.log(image.node.localFile.childImageSharp.fluid.sizes)

  // console.log(overriddenHeroImageSizes.sizes)

  // console.log(image.node.sizes)

  return (
    <>
      {image && (
        <Img
          alt={image?.node?.altText}
          title={title}
          // fluid={image?.node?.localFile?.childImageSharp?.fluid}
          fluid={overriddenHeroImageSizes}
          className={classes}
          placeholderClassName={placeholderClassName || ""}
          fadeIn={true}
          loading={loading}
        />
      )}
    </>
  )
}

export default Image

export const fragmentFeaturedImage = graphql`
  fragment fragmentImage on WpMediaItem {
    __typename
    altText
    sourceUrl
    databaseId
    id
    modified
    description
    srcSet
    sizes
    localFile {
      extension
      publicURL
      childImageSharp {
        # fluid(maxWidth: 1920, srcSetBreakpoints: [800, 1600, 1920]) {
        # fluid(maxWidth: 640) {
        # fluid {
        fluid(maxWidth: 1200, srcSetBreakpoints: [1600, 1920]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
